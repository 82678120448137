/**
 * kontakt.jsx
 * 
 * @file Contact page of the website.
 * @author Robin Walter <hello@robinwalter.me>
 */

import clsx from 'clsx'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'

// internal imports
import { Layout } from '../components/Layout'
import { NoSSR } from '../components/NoSSR'
import { Obfuscate } from '../components/Obfuscate'

/**
 * This Component represents the contact page of this application.
 *
 * @returns {Node} The rendered page.
 */
const KontaktPage = () => {

    // site states
    const [ mail, setMail ] = useState( {
        message: '',
        subject: ''
    } )

    const data = useStaticQuery( graphql`
        query KontaktQuery {
            site {
                siteMetadata {
                    email
                    menuLinks {
                        link
                    }
                }
            }
        }
    ` )

    return (
        <>
            <GatsbySeo title="Kontakt" />
            <Layout>

                <div
                    className={ clsx( 'uk-section-muted', 'uk-section', 'uk-section-large' ) }
                    id="kontakt">
                    <div className={ clsx( 'uk-container' ) }>

                        <h1>Kontakt</h1>

                        <form
                            className={ clsx( 'uk-form-stacked' ) }
                            onSubmit={ e => { e.preventDefault() } }>

                            <div className={ clsx( 'uk-margin' ) }>
                                <label className={ clsx( 'uk-form-label' ) } for="contact-subject">Nennen Sie mir Ihr Anliegen:</label>
                                <div className={ clsx( 'uk-form-controls' ) }>
                                    <input
                                        className={ clsx( 'uk-input' ) }
                                        id="contact-subject"
                                        onChange={ e => { setMail( { ...mail, subject: e.target.value } ) } }
                                        required
                                        type="text"
                                        uk-tooltip="Bitte füllen Sie dieses Feld aus."
                                        value={ mail.subject } />
                                </div>
                            </div>
                            <div className={ clsx( 'uk-margin' ) }>
                                <label className={ clsx( 'uk-form-label' ) } for="contact-message">Und wie lautet Ihre Nachricht?</label>
                                <div className={ clsx( 'uk-form-controls' ) }>
                                    <textarea
                                        className={ clsx( 'uk-textarea' ) }
                                        id="contact-message"
                                        onChange={ e => { setMail( { ...mail, message: e.target.value } ) } }
                                        required
                                        rows={ 15 }
                                        style={ { resize: 'none' } }
                                        uk-tooltip="Bitte füllen Sie dieses Feld aus."
                                        value={ mail.message } />
                                </div>
                            </div>
                            <div className={ clsx( 'uk-margin' ) }>
                                <label className={ clsx( 'uk-form-label' ) } for="contact-send">Senden Sie Ihre Nachricht an mich:</label>
                                <div className={ clsx( 'uk-form-controls' ) }>
                                    <NoSSR>
                                        <Obfuscate
                                            className={ clsx( 'uk-button', 'uk-button-primary', mail.message === '' || mail.subject === '' ? 'uk-invisible' : '' ) }
                                            email={ data.site.siteMetadata.email }
                                            headers={ {
                                                body: mail.message,
                                                subject: mail.subject
                                            } } />
                                    </NoSSR>
                                </div>
                            </div>

                        </form>
                        
                        <p className={ clsx( 'uk-text-meta' ) } style={ { fontSize: '0.75rem' } }>Bitte beachten Sie ebenfalls meine <Link to={ data.site.siteMetadata.menuLinks[ 4 ].link }>Datenschutzerklärung</Link>.</p>

                    </div>
                </div>

            </Layout>
        </>
    )

}

export default KontaktPage
